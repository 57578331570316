Vue.component('filterProxyComponent', {
    props: ['filterField'],
    template: `
            <ul class="child-categories">
                <template v-if="filterField.FilterType=='Range_Number'" >
                    <rangeFilterComponent :filterField="filterField"></rangeFilterComponent>
               </template>
               <template v-else-if="filterField.FilterType=='Classification_List'" >
                    <classificationFilterComponent :filterField="filterField"></classificationFilterComponent>
               </template>
               <template v-else-if="filterField.FilterType=='Value_List'" >
                    <valueFilterComponent :filterField="filterField"></valueFilterComponent>
               </template>
               <template v-else-if="filterField.FilterType=='MultiValue_List'" >
                    <multiValueFilterComponent :filterField="filterField"></multiValueFilterComponent>
               </template>
               <template v-else-if="filterField.FilterType=='Color_Filter'" >
                    <colorFilterComponent :filterField="filterField"></colorFilterComponent>
               </template>
               <template v-else>
                    <p>filtro desconocido</p> 
                    {{filterField.FilterType}}
               </template>
            </ul>
        `,
});